import {
  activateCard as activateCardApi,
  addPersonalDetails,
  addSignUp as addSignUpApi,
  authenticateSmsOtp as authenticateSmsOtpApi,
  declineOffer,
  generateSmsOtp as generateSmsOtpApi,
  refreshAuthToken as refreshAuthTokenApi,
  getCardConfigs,
  getProductGroupPayoffInfo,
  getUser,
  openAccount as openAccountApi,
  putCardConfiguration,
  updateUserAddress,
  authenticateIntercom,
  get as getPhBankVerificationApi,
  getActiveVeriffSession,
  generateVeriffSession as generateVeriffSessionApi,
  queryVeriffDecision as queryVeriffDecisionApi,
  addNrauSignUp as addNrauSignUpApi,
  submitSsnFour,
  SignUpRequestEmailSubscriptionStatusEnum,
  SignUpRequestSmsSubscriptionStatusEnum,
  submitSsnNine,
  submitCredit as submitCreditApi,
  KycResponseEffectivDecisionEnum,
  IdVerificationRequirementEffectivDecisionEnum,
  SubmitCreditRequest,
  DeviceValidationResponse,
  DeviceValidationRequest,
  validateDevice as validateDeviceApi,
  PlaidCheckLinkResponse,
  getPlaidCheckLinkToken,
  getPlaidSessionStatus,
  PhBankPayer,
  PersonalInfoDto,
  Phone,
  getFeatureFlags,
  FlagConfiguration,
  confirmMdv as confirmMdvApi,
} from "@pomebile/pomelo-service-api"

import {
  AddDetailsRequest,
  CardColorSelection,
  CardConfiguration,
  OpenAccountRequest,
  PromoCampaignDtoTypeEnum,
  UserDto,
  UserResponse,
  AuthRequest,
  SignUpRequest,
  VeriffSessionMobileRequest,
  VeriffSessionMobileResponse,
  VeriffDetailsDtoVeriffStateEnum,
  getVeriffFailureReason as getVeriffFailureReasonApi,
  NrauSignUpRequest,
  NrauSignUpResponse,
  RefreshAuthTokenRequest,
  SubmitSsnFourRequest,
  SubmitSsnNineRequest,
} from "@pomebile/pomelo-service-api"

import { AnyRoute, issueRequest } from "./issueRequest"
import type { AuthContext, AuthData, AuthHeaders, Tokens } from "./authContext"
import { withAuth } from "./authContext"
import { mapUserPromos } from "../utils/promo"
import {
  GenericApiError,
  handlerApiError as handleApiError,
  mapResponse,
} from "./handleErrorReponse"
import * as env from "../envConstants"
import { MonthYear, formatAsYYYYMM, formatDateFromStringToArray } from "@pomebile/shared/helpers"
import { NrauSignUpInfo } from "../screens/NrauUserInfo"

/**
 * This error represents an unexpected outcome due to the response and should be
 * logged with high urgency.
 */
export class ResponseValidationError extends Error {
  constructor(public route: AnyRoute, public response: unknown, public message: string) {
    super(`Response validation error for ${route.url}: ${message}`)
  }
}

/**
 * This error represents a response error that we expect to get from the server, but we may
 * not have coded an outcome for it yet (essentially, no action to take for user).
 * We should track these, but with less urgency.
 */
export class ExpectedResponseError extends Error {
  constructor(
    public route: AnyRoute,
    public apiError: GenericApiError,
    public request: unknown,
    public response: Response,
  ) {
    super(`Unhandled an expected response error for ${route.url}: ${response.status}`)
  }
}

function failWith<TErr>(err: TErr): never {
  throw err
}

export type AppApiContext = {
  readonly deviceIdent: string
  readonly baseUrl: string
  readonly vgsUrl: string
  readonly phoneNumber?: string
  readonly inviteCode?: string
  readonly referralCode?: string
  readonly recipientVerificationAttemptId?: string
  readonly country: Country
  readonly accountType: AccountType
  readonly getLogRocketUrl: () => string | null
  anonymousId?: string | null // segment analytics id
  effectivSessionId?: string | null // device id collected by effectiv
}

const headers = (cx: AppApiContext, auth?: AuthHeaders): { [header: string]: string } => {
  const headers: { [header: string]: string } = auth ? { ...auth } : {}
  headers["client-type"] = "WEB-ONBOARDING"

  const logRocketUrl = typeof cx.getLogRocketUrl === "function" ? cx.getLogRocketUrl() : null
  if (logRocketUrl) {
    headers["X-LogRocket-URL"] = logRocketUrl
  }

  if (cx.anonymousId) {
    headers.anonymousId = cx.anonymousId
  }
  if (cx.effectivSessionId) {
    headers.EffectivSessionId = cx.effectivSessionId
  }

  return headers
}

// ----------------------------------

export type GenerateSmsOtpResponse = { tag: "ok" } | { tag: "disabledUser" }

export async function generateSmsOtp(
  cx: AppApiContext,
  country: Country,
  phoneNumber: string,
): Promise<GenerateSmsOtpResponse> {
  const { baseUrl, deviceIdent } = cx

  const req = {
    phoneNumber,
    countryCode: country === "US" ? "1" : "63",
    deviceIdent,
  }

  const resp = issueRequest(
    generateSmsOtpApi,
    {
      req,
    },
    {
      baseUrl,
      headers: headers(cx),
    },
  )

  // Intentionally vague `cause names` so users don't know we've labelled them as fraud/disabled
  // AuthenticationException = disabled user (could be either fraud or non-fraud)
  return await mapResponse(resp, [
    (): GenerateSmsOtpResponse => ({ tag: "ok" }),
    handleApiError([401], "AuthenticationException", () => ({ tag: "disabledUser" })),
  ])
}
// ----------------------------------

interface PhoneWithDefinedNumber extends Phone {
  phoneNumber: string
}
interface PersonalInfoWithDefinedPhone extends PersonalInfoDto {
  phone: PhoneWithDefinedNumber
}
interface UserDtoWithDefinedPhone extends UserDto {
  personalInfo: PersonalInfoWithDefinedPhone
}

interface UserResponseWithDefinedPhone extends UserResponse {
  user?: UserDtoWithDefinedPhone
}

export type AuthTempToken = {
  tag: "newUser"
  smsTempToken: string
}

export type AuthExistingUser = {
  tag: "existingUser"
  token: string
  refreshToken: string
  user: UserDto // should we try to trim down what is in here?
}

export type AuthCodeMismatched = {
  tag: "codeMismatched"
}

export type OTPAuthResponse = AuthTempToken | AuthExistingUser | AuthCodeMismatched

export type UpdateAddressResponse = { tag: "ok" } | { tag: "invalidAddress" }

export async function authenticateSmsOtp(
  smsCode: string,
  country: Country,
  phoneNumber: string,
  cx: AppApiContext,
): Promise<OTPAuthResponse> {
  const { deviceIdent, baseUrl } = cx

  const req: AuthRequest = {
    deviceId: deviceIdent,
    password: smsCode,
    phoneNumber,
    countryCode: country === "US" ? "1" : "63",
  }

  const resp = issueRequest(
    authenticateSmsOtpApi,
    {
      req,
    },
    {
      headers: headers(cx),
      baseUrl,
    },
  )

  return await mapResponse(resp, [
    (res): OTPAuthResponse => {
      const { smsTempToken, token, refreshToken, user } = res
      if (smsTempToken) {
        return { tag: "newUser", smsTempToken }
      }

      if (token && refreshToken && user) {
        return { tag: "existingUser", token, refreshToken, user }
      }

      throw new ResponseValidationError(authenticateSmsOtpApi, res, "Invalid shape of response")
    },
    handleApiError([401], "AuthenticationException", () => ({ tag: "codeMismatched" })),
    handleApiError([403], "AuthenticationException", ({ response, route }, apiError) =>
      failWith(new ExpectedResponseError(route, apiError, req, response)),
    ),
  ])
}

// ----------------------------------
export async function generateToken(
  data: {
    refreshToken: string
  },
  cx: AppApiContext,
): Promise<Tokens> {
  const { refreshToken } = data
  const { deviceIdent, baseUrl } = cx

  const req: RefreshAuthTokenRequest = {
    deviceId: deviceIdent,
    refreshToken,
  }

  const res = await issueRequest(
    refreshAuthTokenApi,
    {
      req,
    },
    {
      headers: headers(cx),
      baseUrl,
    },
  )

  const { authToken: token } = res

  if (token) {
    return { token, refreshToken }
  }

  throw new ResponseValidationError(refreshAuthTokenApi, res, "Invalid shape of response")
}

// ----------------------------------
export interface Promo {
  promoIdent: string
  limit: number
  rate: number
  termsVersion: string
  active: boolean
  type: PromoCampaignDtoTypeEnum
  acceptedTerms: boolean
}

export type AddSignUpResponse = {
  token: string
  refreshToken: string
  userIdent: string
  promos: Promo[]
}

export type NrauUpdateDOBResponse = {
  id?: string
  user: UserDto
}

export type UserPersonalInfo = {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
}

// TODO: update when fixing up the NRAU useinfo screen
export type NrauPersonalInfo = {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  country: Country
  dateOfBirth: string
}

export type AddSignUpOutcome =
  | {
      ok: true
      response: AddSignUpResponse
    }
  | {
      ok: false
      error: "userAlreadyExists"
    }

export type AddNrauSignUpOutcome =
  | {
      ok: true
      response: NrauSignUpResponse
    }
  | {
      ok: false
      error: "userAlreadyExists"
    }
  | {
      ok: false
      error: "invalidInvite"
    }

export type UpdateDOBOutcome = {
  ok: true
  response: NrauUpdateDOBResponse
}

export interface UserMetadata {
  // Note: In order to support easily adding new tags, backend will accept any string->string map
  // Many of these tags are important for Ad attribution and these are the important tags frontend needs to pass.
  additionalTags: {
    fbclid: string | undefined
    fbp: string | undefined
    fbc: string | undefined
    g_client_id: string | undefined
    gclid: string | undefined
    ttclid: string | undefined
    lead_id: string | undefined
    promo_code: string | undefined
    referrer_id: string | undefined
    signup_url: string | undefined
  }
  utmSource: string | undefined
  utmMedium: string | undefined
  utmCampaign: string | undefined
  utmContent: string | undefined
  utmTerm: string | undefined
}

export type UserData = UserPersonalInfo & { optInMarketing: boolean }
export async function addSignUp(
  smsToken: string,
  userData: UserData,
  userMetadata: UserMetadata,
  cx: AppApiContext,
): Promise<AddSignUpOutcome> {
  const { baseUrl, deviceIdent, referralCode } = cx
  const { firstName, lastName, email, optInMarketing } = userData

  const req: SignUpRequest = {
    deviceId: deviceIdent,
    email,
    firstName,
    lastName,
    smsTempToken: smsToken,
    referralCode,
    metadata: {
      utmCampaign: userMetadata.utmCampaign,
      utmContent: userMetadata.utmContent,
      utmMedium: userMetadata.utmMedium,
      utmSource: userMetadata.utmSource,
      utmTerm: userMetadata.utmTerm,
      // API codegen is not happy with string->string map, wants string->object (but backend will accept string as an object so this is okay)
      additionalTags: userMetadata.additionalTags as any,
    },
    clientType: "WEB",
    emailSubscriptionStatus: optInMarketing
      ? SignUpRequestEmailSubscriptionStatusEnum.Subscribed
      : SignUpRequestEmailSubscriptionStatusEnum.Unsubscribed,
    smsSubscriptionStatus: optInMarketing
      ? SignUpRequestSmsSubscriptionStatusEnum.Subscribed
      : SignUpRequestSmsSubscriptionStatusEnum.Unsubscribed,
  }
  const res = issueRequest(
    addSignUpApi,
    {
      req,
    },

    {
      headers: headers(cx),
      baseUrl,
    },
  )

  return await mapResponse(res, [
    (res): AddSignUpOutcome => {
      const { token, refreshToken, user } = res

      if (!token || !refreshToken || !user?.ident) {
        throw new ResponseValidationError(addSignUpApi, res, "Invalid shape of response")
      }

      const userPromos = user.promos ? mapUserPromos(user.promos) : []

      return {
        ok: true,
        response: {
          token,
          refreshToken,
          userIdent: user.ident,
          promos: userPromos,
        },
      }
    },
    handleApiError([409], "UserAlreadyExistsException", () => ({
      ok: false,
      error: "userAlreadyExists",
    })),
  ])
}

export async function addNrauDateOfBirth(
  dateOfBirth: [year: number, month: number, date: number],
  userIdent: string,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<UpdateDOBOutcome> {
  // TODO: Change to appropriate type.
  const req = { dateOfBirth, userIdent }
  const resp = submitPersonalDetails(req, cx, auth, authCx, {
    secure: true,
  })

  return await mapResponse(resp, [
    (res): UpdateDOBOutcome => {
      // TODO: Do we "REQUIRE" id here? the response from addPersonalDetails is expected to have and id param but it doesnt seem to adhere to that.
      if (!res.user) {
        throw new ResponseValidationError(addPersonalDetails, res, "Invalid shape of response")
      }

      return {
        ok: true,
        response: {
          id: res.id,
          user: res.user,
        },
      }
    },
  ])
}

export async function addNrauSignUp(
  smsToken: string,
  userData: NrauSignUpInfo,
  inviteCode: string,
  cx: AppApiContext,
): Promise<AddNrauSignUpOutcome> {
  const { baseUrl, deviceIdent } = cx
  const { firstName, lastName, email, dateOfBirth } = userData

  // TODO need to refactor dates with Simon now that we have DateDTO. This is a bad way to do this.
  const [birthYear, birthMonth, birthDay] = formatDateFromStringToArray(dateOfBirth)
  const req: NrauSignUpRequest = {
    deviceId: deviceIdent,
    email,
    firstName,
    lastName,
    smsTempToken: smsToken,
    inviteCode,
    birthDate: {
      day: birthDay,
      month: birthMonth,
      year: birthYear,
    },
    clientType: "WEB",
  }
  const res = issueRequest(
    addNrauSignUpApi,
    {
      req,
    },

    {
      headers: headers(cx),
      baseUrl,
    },
  )

  return await mapResponse(res, [
    (res): AddNrauSignUpOutcome => {
      const { authToken, refreshToken, user } = res

      if (!authToken || !refreshToken || !user?.ident) {
        throw new ResponseValidationError(addNrauSignUpApi, res, "Invalid shape of response")
      }

      return {
        ok: true,
        response: res,
      } satisfies AddNrauSignUpOutcome
    },
    handleApiError(
      [409],
      "UserAlreadyExistsException",
      (): AddNrauSignUpOutcome => ({
        ok: false,
        error: "userAlreadyExists",
      }),
    ),
    handleApiError(
      "*",
      "InvalidInviteException",
      (): AddNrauSignUpOutcome => ({
        ok: false,
        error: "invalidInvite",
      }),
    ),
  ])
}

// ----------------------------------
export type PHAddress = {
  addressLineOne: string
  addressLineTwo?: string | undefined
  barangay: string
  cityOrMunicipality: string
  province: string
  postalCode: string | undefined
  country: "PH"
}

export type USAddress = {
  addressLineOne: string
  addressLineTwo?: string | undefined
  city: string
  state: string
  zip?: string | undefined
  country: "US"
}

export type AnyAddress = PHAddress | USAddress
export type Country = AnyAddress["country"]
export type AccountType = "RPC" | "NRAU"

export function isSupportedCountry(country: string): country is Country {
  return country === "PH" || country === "US"
}

async function updateAddress(
  userIdent: string,
  address: {
    lineOne: string
    lineTwo?: string | undefined
    city: string
    locality?: string
    region: string
    zip?: string | undefined
    country: string
  },
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<UserResponse> {
  const { baseUrl } = cx

  const res = await withAuth(
    (authHeaders) =>
      issueRequest(
        updateUserAddress,
        {
          req: {
            ...address,
            userIdent,
          },
        },
        {
          baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )

  return res
}

// TODO: Update address call is the same, so merge this together?
export async function updatePHAddress(
  userIdent: string,
  address: PHAddress,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<void> {
  const { baseUrl } = cx
  const {
    addressLineOne,
    addressLineTwo,
    barangay,
    cityOrMunicipality,
    province,
    postalCode,
    country,
  } = address

  const _res = updateAddress(
    userIdent,
    {
      lineOne: addressLineOne,
      lineTwo: addressLineTwo,
      locality: barangay,
      city: cityOrMunicipality,
      region: province,
      zip: postalCode,
      country,
    },
    cx,
    auth,
    authCx,
  )
}

export async function updateUSAddress(
  userIdent: string,
  address: USAddress,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<UpdateAddressResponse> {
  const { baseUrl } = cx
  const { addressLineOne, addressLineTwo, city, state, zip, country } = address

  const res = updateAddress(
    userIdent,
    {
      lineOne: addressLineOne,
      lineTwo: addressLineTwo,
      city,
      region: state,
      zip,
      country,
    },
    cx,
    auth,
    authCx,
  )

  return await mapResponse(res, [
    (): UpdateAddressResponse => ({ tag: "ok" }),
    handleApiError([409], "InvalidAddressException", (err): UpdateAddressResponse => {
      const errorType = err.payload.errorType
      const rejectionReason = err.payload.metadata.rejectionReason
      if (errorType === "INVALID_ADDRESS" && rejectionReason === "PO_BOX") {
        return {
          tag: "invalidAddress",
        }
      } else {
        throw err
      }
    }),
  ])
}

// ----------------------------------

async function submitPersonalDetails(
  data: AddDetailsRequest,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
  options?: {
    secure: boolean
  },
): Promise<UserResponse> {
  const { baseUrl, vgsUrl } = cx
  const { secure = false } = options ?? {}

  return withAuth(
    (authHeaders) =>
      issueRequest(
        addPersonalDetails,
        {
          req: data,
        },
        {
          baseUrl: secure ? vgsUrl : baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )
}

export type SsnRequirementStatus = "notStarted" | "complete" | "requiresFullSSN"
export type VeriffRequirementStatus = "notRequired" | "complete" | "required"

export async function addDateOfBirth(
  dateOfBirth: [year: number, month: number, date: number],
  userIdent: string,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<void> {
  const req = { dateOfBirth, userIdent }
  await submitPersonalDetails(req, cx, auth, authCx, { secure: true })
}

export async function addSsn(
  ssn: string,
  userIdent: string,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<SsnRequirementStatus> {
  const req = { ssn, userIdent }
  const resp = submitPersonalDetails(req, cx, auth, authCx, {
    secure: true,
  })

  return await mapResponse(resp, [
    (res): SsnRequirementStatus => {
      const { signUpRequirementStatus, fallbackFullNine } =
        res.user?.signUp.signUpContextDto?.ssnRequirement ?? {}

      if (signUpRequirementStatus === "COMPLETE") {
        return "complete"
      }
      return fallbackFullNine ? "requiresFullSSN" : "notStarted"
    },
    handleApiError([409], "DuplicateSsnException", ({ response, route }, apiError) =>
      failWith(new ExpectedResponseError(route, apiError, req, response)),
    ),
  ])
}

export async function generatePlaidLink(
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<PlaidCheckLinkResponse> {
  const res = withAuth(
    (authHeaders) =>
      issueRequest(
        getPlaidCheckLinkToken,
        {
          req: {
            redirectUri: "https://secure.plaid.com/oauth/redirect",
            completionRedirectUri: document.location.origin,
            origin: "ONBOARDING",
          },
        },
        {
          baseUrl: cx.baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )

  return await mapResponse(res, [
    (res): PlaidCheckLinkResponse => res,
    handleApiError([403], "AccessDeniedException", () => ({
      hostedLinkUrl: "",
      linkToken: "",
      expirationTs: 0,
    })),
  ])
}

export async function checkPlaidSessionStatus(
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
) {
  return withAuth(
    (authHeaders) =>
      issueRequest(
        getPlaidSessionStatus,
        {},
        {
          baseUrl: cx.baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )
}

// ----------------------------------

export type SubmitApplicationResponse = {
  ok: true
}

// TODO Do we still need this function?
export async function submitApplication(
  _userIdent: string,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<SubmitApplicationResponse> {
  const data: SubmitCreditRequest = {}

  await withAuth(
    (authHeaders) =>
      issueRequest(
        submitCreditApi,
        {
          req: data,
        },
        {
          baseUrl: cx.baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )

  return { ok: true }
}

// ----------------------------------
// export interface CreditApplicationResults {
//   rejected: void
//   approvedSecured: void
//   approvedUnsecured: string | undefined
//   manualReview: void
// }
export type CreditApplicationOutcome =
  | {
      tag: "approvedSecured" | "approvedUnsecured"
      creditAppIdent?: string
      updatedPromos: Promo[]
    }
  | {
      tag: "veriffRequired"
    }
  | {
      tag: "manualReview"
    }
  | {
      tag: "rejected"
    }
  | {
      // This is actually not returned from the route, more of a convenience for form to signal failed Api
      tag: "errorSubmittingApplication"
    }
  | {
      tag: "frozenCredit"
    }

export async function checkApplicationStatus(
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<Exclude<CreditApplicationOutcome, { tag: "errorSubmittingApplication" }> | undefined> {
  const user = await withAuth(
    (authHeaders) =>
      issueRequest(
        getUser,
        {},
        {
          baseUrl: cx.baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )

  let effectivDecision =
    user.user?.signUp.signUpContextDto?.idVerificationRequirement?.effectivDecision
  const veriffState =
    user.user?.signUp.signUpContextDto?.idVerificationRequirement?.veriffDetails?.veriffState
  const terminalVeriffStates: VeriffDetailsDtoVeriffStateEnum[] = ["review", "declined", "approved"]

  const creditAppIdent = user.user?.approvedCreditAppDto.ident

  if (!effectivDecision && env.BUILD_MODE !== "production") {
    // We're not hitting the Effectiv APIs in non-production environments so this would be null/undefined.
    // However, we still want to proceed with the flow so we're bypassing Effectiv here for test/dev environments.
    effectivDecision = "APPROVE"
  }

  switch (effectivDecision) {
    case "DECLINE":
      return { tag: "rejected" }

    case "CANCEL":
      return { tag: "manualReview" }

    case "REVIEW":
      if (veriffState && terminalVeriffStates.includes(veriffState)) {
        return { tag: "manualReview" }
      } else {
        return { tag: "veriffRequired" }
      }

    case "APPROVE":
      const isUserCreditFrozen: boolean =
        user.user?.signUp.signUpContextDto?.underwritingRequirement?.frozenCredit || false

      if (isUserCreditFrozen) {
        return { tag: "frozenCredit" }
      }

      const productType = user.user?.signUp.signUpContextDto?.underwritingRequirement?.productType
      // Once users are approved for a plan, we also want to get their updated promos. This is mainly to check
      // if they've accepted the terms for the promos that we're offering
      const updatedPromos = user.user?.promos ? mapUserPromos(user.user?.promos) : []

      switch (productType) {
        case "CHARGE_CARD_SECURED":
          return { tag: "approvedSecured", creditAppIdent, updatedPromos }

        case "CHARGE_CARD_UNSECURED":
          return { tag: "approvedUnsecured", creditAppIdent, updatedPromos }

        default:
          return undefined
      }

    default:
      return undefined
  }

  // NOT_STARTED = credit application hasn't been called OR submission in progress
  // const spinning =
  //   user.user?.signUp.signUpContextDto?.underwritingRequirement?.signUpRequirementStatus ===
  //   "NOT_STARTED"

  // All of this logic lives here:
  // https://github.com/pomelo-co/pomelo-server-v1/blob/main/src/main/java/co/pomelo/server/v1/entity/mobile/signup/state/function/SignUpRequirementsChecker.java
}

// ----------------------------------
export async function fetchCardConfigs(
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<CardConfiguration[]> {
  const { baseUrl } = cx

  return withAuth(
    (authHeaders) =>
      issueRequest(
        getCardConfigs,
        {},
        {
          baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )
}

// ----------------------------------
export type CreditAppIdent = string

export async function submitCardConfiguration(
  data: {
    cardId: string
    userIdent: string
  },
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<CreditAppIdent> {
  const { baseUrl } = cx
  const req: CardColorSelection = data

  const res = await withAuth(
    (authHeaders) =>
      issueRequest(
        putCardConfiguration,
        { req },
        {
          baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )

  const creditAppIdent = res.approvedCreditAppDto.ident

  if (!creditAppIdent) {
    throw new ResponseValidationError(refreshAuthTokenApi, res, "Invalid shape of response")
  }

  return creditAppIdent
}

// ----------------------------------

export type NewAccountResponse = {
  productGroupIdent: string
  updatedPromos: Promo[]
}

export async function openAccount(
  data: OpenAccountRequest,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<NewAccountResponse> {
  const { baseUrl } = cx

  const res = await withAuth(
    (authHeaders) =>
      issueRequest(
        openAccountApi,
        { req: data },
        {
          baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )

  if (!res.productGroup?.ident) {
    throw new ResponseValidationError(openAccountApi, res, "Invalid product group response")
  }

  return {
    productGroupIdent: res.productGroup.ident,
    updatedPromos: res.user?.promos ? mapUserPromos(res.user?.promos) : [],
  }
}

// ----------------------------------
export interface GCashExchangeRate {
  rate: number
  exchangeRateIdent: string
  expirationTs: number
}

// ----------------------------------

export interface DebitCardPaymentMethod {
  id: string
  type: "debit"
  last4: string
  issuer: string
  expiration?: string
}

export interface PomeloCardPaymentMethod {
  id: string
  type: "credit"
  last4: string
  issuer: string
  expiration?: string
  currentCycleDueDate: Date
  availableCredit: number
}

export type PayoffInfoResponse = {
  ok: true
  currentCycleDueDate: number[]
}

async function getPayOffInfo(
  productGroupIdent: string,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<PayoffInfoResponse> {
  const { baseUrl } = cx
  const payOffInfoResp = withAuth(
    (authHeaders) =>
      issueRequest(
        getProductGroupPayoffInfo,
        {
          pathParams: {
            productGroupIdent,
          },
        },
        {
          baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )

  return await mapResponse(payOffInfoResp, [
    (res): PayoffInfoResponse => {
      if (!res.currentCycleDueDate) {
        throw new ResponseValidationError(
          getProductGroupPayoffInfo,
          res,
          "Invalid Payoff Info Response",
        )
      }

      return {
        ok: true,
        currentCycleDueDate: res.currentCycleDueDate,
      }
    },
  ])
}

// ----------------------------------

export async function authenticateIntercomUsingGet(
  userIdent: string,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<string> {
  const { baseUrl } = cx

  const queryParams = {
    operatingSystem: "WEB",
    userIdent,
  }

  const res = await withAuth(
    (authHeaders) =>
      issueRequest(
        authenticateIntercom,
        {
          queryParams,
        },
        {
          baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )

  if (!res.userHash) {
    throw new ResponseValidationError(
      authenticateIntercom,
      res,
      "Invalid Intercom Authentication Response",
    )
  }

  return res.userHash
}

export type ActiveVeriffSessionResponse =
  | {
      hasSession: true
      url: string
    }
  | {
      hasSession: false
      url?: never
    }

export async function getActiveVeriffSessionUsingPost(
  userIdent: string,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<ActiveVeriffSessionResponse> {
  const { baseUrl } = cx

  const req: VeriffSessionMobileRequest = {
    userIdent,
  }

  const res = await withAuth(
    (authHeaders) =>
      issueRequest(
        getActiveVeriffSession,
        { req },
        {
          baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )

  if (res.url) {
    return {
      hasSession: true,
      url: res.url,
    }
  } else {
    return {
      hasSession: false,
    }
  }
}

export type GenerateVeriffResponse =
  | {
      ok: true
      url: string
    }
  | {
      ok: false
      errorType: "max_attempts_reached" | "unknown_error"
    }

export async function generateVeriffSession(
  userIdent: string,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<GenerateVeriffResponse> {
  const { baseUrl } = cx

  const req: VeriffSessionMobileRequest = {
    userIdent,
  }

  const resp = withAuth(
    (authHeaders) =>
      issueRequest(
        generateVeriffSessionApi,
        { req },
        {
          baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )

  return await mapResponse(resp, [
    (res: VeriffSessionMobileResponse): GenerateVeriffResponse => {
      if (!res.veriffSessionResponse?.verification?.url) {
        throw new ResponseValidationError(
          generateVeriffSessionApi,
          res,
          "No veriff URL in response shape.",
        )
      }
      return {
        ok: true,
        url: res.veriffSessionResponse.verification.url,
      }
    },
    handleApiError("*", "TooManyFailedAttemptsException", () => ({
      ok: false,
      errorType: "max_attempts_reached",
    })),
  ])
}

export async function getVeriffFailureReason(
  userIdent: string,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<string | undefined> {
  const { baseUrl } = cx

  const req: VeriffSessionMobileRequest = {
    userIdent,
  }

  const res = await withAuth(
    (authHeaders) =>
      issueRequest(
        getVeriffFailureReasonApi,
        { req },
        {
          baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )

  return res.reason
}

export type VeriffDecisionResponse =
  | {
      tag: "pending"
    }
  | {
      tag: "approved"
    }
  | {
      tag: "declined"
    }
  | {
      tag: "review"
    }
  | {
      tag: "requires_resubmission"
      reason?: string
    }

export async function queryVeriffDecision(
  userIdent: string,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<VeriffDecisionResponse> {
  const { baseUrl } = cx

  const req: VeriffSessionMobileRequest = {
    userIdent,
  }

  const res = await withAuth(
    (authHeaders) =>
      issueRequest(
        queryVeriffDecisionApi,
        { req },
        {
          baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )

  // Note: This API call makes another API call within itself, due to an unfortunate API design on the BE.
  // We should try to avoid doing this!!
  // TODO fix backend API to all this information in a single API
  if (res.veriffState === "resubmission_requested") {
    const responseFailure = await getVeriffFailureReason(userIdent, cx, auth, authCx)

    return {
      tag: "requires_resubmission",
      reason: responseFailure,
    }
  }

  switch (res.veriffState) {
    case "approved":
      return { tag: "approved" }
    case "declined":
      return { tag: "declined" }
    case "review":
      return { tag: "review" }
    default:
      return { tag: "pending" }
  }
}

// ----------------------------------

export async function addDeclineReason(
  reason: string,
  userIdent: string,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<UserResponse> {
  const { baseUrl } = cx

  return withAuth(
    (authHeaders) =>
      issueRequest(
        declineOffer,
        {
          req: {
            id: userIdent,
            reason: reason,
            userIdent: userIdent,
          },
        },
        {
          baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )
}

// ----------------------- CARD ACTIVATION -----------------------

export async function activateCard(
  cardIdent: string,
  cvcNumber: string,
  expiryDate: MonthYear,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<void> {
  const { baseUrl } = cx

  await withAuth(
    (authHeaders) =>
      issueRequest(
        activateCardApi,
        {
          req: {
            cardIdent,
            expiryDate: formatAsYYYYMM(expiryDate, "2000", "-"),
            cvv: cvcNumber,
          },
        },
        {
          baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )
}

// ----------------------- END OF CARD ACTIVATION -----------------------

// ----------------------- KYC -----------------------
export type TerminalKycOutcome = "approved" | "manualReview" | "rejected"

export type Last4SsnOutcome =
  | TerminalKycOutcome
  | "requiresFullSsn"
  | "requiresVeriff"
  | "unexpectedResult"

export type Full9SsnOutcome = TerminalKycOutcome | "requiresVeriff" | "unexpectedResult"

const determineOutcome = (
  effectivDecision: KycResponseEffectivDecisionEnum | undefined,
): TerminalKycOutcome | "requiresVeriff" | "unexpectedResult" => {
  switch (effectivDecision) {
    case "APPROVE":
      return "approved"

    case "REVIEW":
      return "requiresVeriff"

    case "DECLINE":
      return "rejected"
  }

  return "unexpectedResult"
}

export async function submitLast4Ssn(
  last4Ssn: string,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<Last4SsnOutcome> {
  const { baseUrl } = cx

  const req: SubmitSsnFourRequest = { ssnFour: last4Ssn }

  const res = await withAuth(
    (authHeaders) =>
      issueRequest(
        submitSsnFour,
        { req },
        {
          baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )

  const requiresFullSsn = res.tag === "REQUIRE_FULL_NINE"

  if (requiresFullSsn) {
    return "requiresFullSsn"
  }

  return determineOutcome(res.effectivDecision)
}

export type FullSsnOutcome = TerminalKycOutcome | "requiresVeriff" | "unexpectedResult"

export async function submitFullSsn(
  fullSsn: string,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<FullSsnOutcome> {
  const { vgsUrl } = cx
  const req: SubmitSsnNineRequest = { ssnNine: fullSsn }

  const res = await withAuth(
    (authHeaders) =>
      issueRequest(
        submitSsnNine,
        { req },
        {
          baseUrl: vgsUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )

  return determineOutcome(res.effectivDecision)
}

export type EffectivDecision =
  | {
      tag: Extract<
        IdVerificationRequirementEffectivDecisionEnum,
        "APPROVE" | "REVIEW" | "DECLINE" | "CANCEL"
      >
    }
  | { tag: "unsupportedResult" } // unsupportedResult expresses that the current Effectiv decision is not something we should handle in FE

export async function queryEffectivDecision(
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<EffectivDecision> {
  const { user } = await withAuth(
    (authHeaders) =>
      issueRequest(
        getUser,
        {},
        {
          baseUrl: cx.baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )

  const effectivDecision =
    user?.signUp.signUpContextDto?.idVerificationRequirement?.effectivDecision

  if (
    effectivDecision === "APPROVE" ||
    effectivDecision === "REVIEW" ||
    effectivDecision === "CANCEL" ||
    effectivDecision === "DECLINE"
  ) {
    return { tag: effectivDecision }
  }

  return { tag: "unsupportedResult" }
}

// ----------------------- END OF KYC -----------------------

// ----------------------- SUBMIT CREDIT -----------------------
export type SubmitCreditResponse =
  | { tag: "frozen_credit" }
  | { tag: "too_many_attempts" }
  | {
      tag: "secured" | "unsecured"
      creditAppIdent: string
      approvedLimit: number
      expiration: number
    }

export async function submitCredit(
  data: Required<SubmitCreditRequest>,
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<SubmitCreditResponse> {
  const resp = withAuth(
    (authHeaders) =>
      issueRequest(
        submitCreditApi,
        { req: data },
        {
          baseUrl: cx.baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )

  return await mapResponse(resp, [
    (res): SubmitCreditResponse => {
      if (res.tag === "FROZEN_CREDIT") {
        return { tag: "frozen_credit" }
      }

      if (res.tag === "MAX_CREDIT_PULLS") {
        return { tag: "too_many_attempts" }
      }

      return {
        tag: res.offer.productType === "CHARGE_CARD_SECURED" ? "secured" : "unsecured",
        creditAppIdent: res.offer.id,
        approvedLimit: res.offer.approvedLimit,
        expiration: res.offer.expiration,
      }
    },
    handleApiError([409], "InvalidStateException", () => ({ tag: "too_many_attempts" })),
  ])
}
// ----------------------- END OF SUBMIT CREDIT -----------------------

// ----------------------- DEVICE VALIDATION -----------------------

export async function queryDeviceVerification(
  token: string,
  baseUrl: string,
): Promise<DeviceValidationResponse> {
  const req: DeviceValidationRequest = {
    token,
  }

  const res = await issueRequest(
    validateDeviceApi,
    { req },
    {
      baseUrl,
      headers: {
        "client-type": "WEB-ONBOARDING",
      },
    },
  )

  return res
}

// ----------------------- END OF DEVICE VALIDATION -----------------------

// ----------------------- RECIPIENT VERIFICATION -----------------------

const phBankPayerTypeMap: { [key in PhBankPayer]: string } = {
  ALLBANK: "AllBank",
  ASIA_UNITED_BANK: "Asia United Bank",
  BANCO_DE_ORO: "Banco De Oro",
  BANGKO_MABUHAY: "Bangko Mabuhay",
  BANK_OF_COMMERCE: "Bank Of Commerce",
  BANK_OF_THE_PHILIPPINE_ISLANDS: "Bank Of The Philippine Islands",
  BDO_NETWORK_BANK: "BDO Network Bank",
  BPI_DIRECT_BANKO: "BPI Direct Banko",
  BPI_FAMILY_SAVINGS_BANK: "BPI Family Savings Bank",
  CAMALIG_BANK: "Camalig Bank",
  CARD_BANK_INC: "Card Bank Inc",
  CEBUANA_LHUILLIER_RURAL_BANK: "Cebuana Lhuillier Rural Bank",
  CHINABANK: "Chinabank",
  CHINA_BANK_SAVINGS: "China Bank Savings",
  CTBC_BANK_CORPORATION: "CTBC Bank Corporation",
  DEVELOPMENT_BANK_OF_PHILIPPINES: "Development Bank Of Philippines",
  DUNGGANON_BANK: "Dungganon Bank",
  EAST_WEST_BANK: "East West Bank",
  EAST_WEST_RURAL_BANK: "East West Rural Bank",
  ENTREPRENEUR_BANK: "Entrepreneur Bank",
  EQUICOM_SAVINGS_BANK: "Equicom Savings Bank",
  GOTYME_BANK: "GoTyme Bank",
  ISLA_BANK: "Isla Bank",
  LAND_BANK_OF_THE_PHILIPPINES: "Land Bank Of The Philippines",
  LUZON_DEVELOPMENT_BANK: "Luzon Development Bank",
  MALAYAN_SAVINGS_BANK: "Malayan Savings Bank",
  MAYA_BANK_INC: "Maya Bank Inc",
  MAYBANK: "Maybank",
  METROBANK: "Metrobank",
  MINDANAO_CONSOLIDATED_COOPERATIVE_BANK: "Mindanao Consolidated Cooperative Bank",
  OMNI_PAY_INC: "Omni Pay Inc",
  PACIFIC_ACE_SAVINGS_BANK: "Pacific Ace Savings Bank",
  PARTNER_RURAL_BANK: "Partner Rural Bank",
  PHILIPPINE_BANK_OF_COMMUNICATIONS: "Philippine Bank Of Communications",
  PHILIPPINE_BUSINESS_BANK: "Philippine Business Bank",
  PHILIPPINE_NATIONAL_BANK: "Philippine National Bank",
  PHILIPPINE_VETERANS_BANK: "Philippine Veterans Bank",
  PHILTRUST_BANK: "Philtrust Bank",
  PNB_SAVINGS_BANK: "PNB Savings Bank",
  PORAC_BANK: "Porac Bank",
  PRODUCERS_BANK: "Producers Bank",
  PSBANK: "PS Bank",
  QCRB: "QCRB",
  QUEENBANK: "Queenbank",
  RCBC: "RCBC",
  RCBC_SAVINGS_BANK: "RCBC Savings Bank",
  ROBINSONS_BANK: "Robinsons Bank",
  SEABANK_PHILIPPINES: "Seabank Philippines",
  SECURITY_BANK: "Security Bank",
  STERLING_BANK_OF_ASIA: "Sterling Bank Of Asia",
  SUN_SAVINGS_BANK: "Sun Savings Bank",
  UNION_BANK_OF_THE_PHILIPPINES: "Union Bank Of The Philippines",
  UNION_DIGITAL_BANK: "Union Digital Bank",
  UNOBANK_INC: "Unobank Inc",
  WEALTH_BANK: "Wealth Bank",
}

export type RecipientVerificationValidUrl = {
  senderName: string
  transferAmount: number
  recipientBankPayer: string
  recipientBankLast4: string
  linkExpirationTs: string
  isMdvRequired: boolean
  veriff:
    | {
        tag: "created"
        url: string
      }
    | {
        tag: "resubmit"
        url: string
      }
    | {
        tag: "submitted"
      }
    | {
        tag: "declined"
      }
    | {
        tag: "approved"
      }
}

export type UniqueLinkResponse =
  | {
      tag: "validUrl"
      info: RecipientVerificationValidUrl
    }
  | {
      tag: "error"
      message: string
    }

export async function queryUniqueLinkInfo(
  attemptId: string,
  cx: AppApiContext,
): Promise<UniqueLinkResponse> {
  const resp = issueRequest(
    getPhBankVerificationApi,
    {
      pathParams: {
        id: attemptId,
      },
    },
    {
      baseUrl: cx.baseUrl,
      headers: headers(cx),
    },
  )

  return await mapResponse(resp, [
    (res): UniqueLinkResponse => {
      if (res.tag !== "success") {
        return {
          tag: "error",
          message: "Error response",
        }
      }

      const { bankPayer, expiresAt, last4, mdvRequired, senderFirstName, transferAmount, veriff } =
        res.info

      return {
        tag: "validUrl",
        info: {
          isMdvRequired: mdvRequired,
          linkExpirationTs: expiresAt,
          recipientBankLast4: last4,
          recipientBankPayer: phBankPayerTypeMap[bankPayer],
          senderName: senderFirstName,
          transferAmount,
          veriff,
        },
      }
    },
    handleApiError("*", { cause: "*" }, () => ({ tag: "error", message: "API error" })),
  ])
}

export type MicroDepositVerificationResponse =
  | {
      tag: "success"
    }
  | {
      tag: "incorrect"
    }
  | {
      tag: "tooManyAttempts"
    }
  | {
      tag: "unexpectedError"
    }

export async function submitMicroDepositAmount(
  attemptId: string,
  depositAmount: number,
  cx: AppApiContext,
): Promise<MicroDepositVerificationResponse> {
  const resp = issueRequest(
    confirmMdvApi,
    {
      req: {
        mdvAmountCentavos: depositAmount,
        phBankVerificationAttemptId: attemptId,
      },
    },
    {
      baseUrl: cx.baseUrl,
      headers: headers(cx),
    },
  )

  return await mapResponse(resp, [
    (res): MicroDepositVerificationResponse => {
      switch (res.tag) {
        case "confirmMdvResponseSuccess":
          return { tag: "success" }

        case "confirmMdvResponseAmountMismatch":
          return { tag: "incorrect" }

        case "confirmMdvResponseAttemptLimitExceeded":
          return { tag: "tooManyAttempts" }

        default:
          return { tag: "unexpectedError" }
      }
    },
    handleApiError("*", { cause: "*" }, () => ({ tag: "unexpectedError" })),
  ])
}

export type RecipientVerificationVeriffSdkResponse =
  | {
      type: "reload_required"
    }
  | {
      type: "finished"
    }
  | {
      type: "user_cancelled"
    }

export type VeriffSdkError = "sdk_needs_reload" | "user_cancelled" | "cannot_open" | "network_error"

export async function startRecipientVerificationVeriffSdk(): Promise<RecipientVerificationVeriffSdkResponse> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ type: "finished" })
    }, 2000)
  })
}

// ----------------------- END OF RECIPIENT VERIFICATION -----------------------
// ----------------------- FETCH USER -----------------------

export async function fetchUserFromTokens(
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<UserResponseWithDefinedPhone> {
  const res = withAuth(
    (authHeaders) =>
      issueRequest(
        getUser,
        {},
        {
          baseUrl: cx.baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )

  return await mapResponse(res, [
    (res): UserResponseWithDefinedPhone => {
      if (!res.user?.personalInfo?.phone?.phoneNumber) {
        throw new ResponseValidationError(getUser, res, "No phone number in personalInfo response.")
      }
      return res as UserResponseWithDefinedPhone
    },
  ])
}

// ----------------------- END OF FETCH USER -----------------------
// ----------------------- FEATURE FLAGS ----------------------------
// This function returns the subset of BE flags that have been configured
// to be passed to the client, and are evaluating to true. Those flags are
// configured in the `backend` LD environment (not `pomelo`). It is
// currently an authed route that returns user-specific flag evaluations.
export async function fetchEnabledFeatureFlags(
  cx: AppApiContext,
  auth: AuthData,
  authCx: AuthContext,
): Promise<FlagConfiguration> {
  return withAuth(
    (authHeaders) =>
      issueRequest(
        getFeatureFlags,
        {},
        {
          baseUrl: cx.baseUrl,
          headers: headers(cx, authHeaders),
        },
      ),
    auth,
    authCx,
  )
}
// ----------------------- END OF FEATURE FLAGS ---------------------
