/* tslint:disable */
/* eslint-disable */
/**
 * Pomelo Backend Server
 * This is the backend API
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
  ApiError,
  DeviceValidationRequest,
  DeviceValidationResponse,
  ResendDeviceConfirmationEmailRequest,
  ResendDeviceConfirmationEmailResponse,
  UpdateDeviceRequest,
  UpdateFirebaseTokenRequest,
} from "../models"

export const addFirebaseToken = {
  url: "/v1/device",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: UpdateFirebaseTokenRequest
    res: void
  },
} as const

export const resendDeviceValidationEmail = {
  url: "/v1/deviceEmail",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: ResendDeviceConfirmationEmailRequest
    res: ResendDeviceConfirmationEmailResponse
  },
} as const

export const updateDevice = {
  url: "/v1/updateDevice",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: UpdateDeviceRequest
    res: void
  },
} as const

export const validateDevice = {
  url: "/v1/deviceValidation",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: DeviceValidationRequest
    res: DeviceValidationResponse
  },
} as const
